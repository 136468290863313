import { ValidationError, array, mixed, object } from 'yup'
import { ChannelType, CreativeRotationType, EndemicFormatType } from '@lasso/api-activation/activation'

import {
  WhenConditionCallback,
  extendSchema,
  oneOfEnum, typeOnlySchema,
} from '@lasso/shared/validation'

import { AdGroupCreative } from '../../../shared/creatives'
import { eNewsLetterEndemicFormats, endemicChannels } from '../../../shared/channels'

import { getCreativesTotalWeight, groupCreatives } from './utils'

type SchemaInput = {
  channelId: ChannelType
  endemicFormatId: EndemicFormatType | null
  creativeRotationType: CreativeRotationType
  areCreativesRequired: boolean
}

const whenRotationTypeCallback: WhenConditionCallback<CreativeRotationType> = (schema, callback) => {
  return schema.when(
    'creatives.rotationType',
    (value) => {
      return callback(value)
    },
  )
}

export const buildCardCreativesSchema = ({
  isCardEnabled,
  whenChannelId,
  whenEndemicFormatId,
  areCreativesRequired = false,
}: {
  isCardEnabled: boolean
  whenChannelId: WhenConditionCallback<ChannelType>
  whenEndemicFormatId: WhenConditionCallback<EndemicFormatType | null>
  areCreativesRequired?: boolean
}) => {
  const baseSchemaShape = {
    totalAllocatedValidation: mixed(),
    rotationType: oneOfEnum(CreativeRotationType).default(CreativeRotationType.AUTO),
    creatives: array(typeOnlySchema<AdGroupCreative>()).default([]),
  }

  const baseSchema = object(baseSchemaShape).required()

  if (!isCardEnabled) {
    return { creatives: baseSchema }
  }

  const buildSchema = ({ channelId, endemicFormatId, areCreativesRequired, creativeRotationType }: SchemaInput) => {
    return extendSchema(baseSchemaShape, {
      creatives: (schema) => {
        if (areCreativesRequired) {
          schema = schema.min(1)
        }

        return schema.test(
          'validateCreatives',
          (creatives, context) => {
            const errors = []

            if (creativeRotationType === CreativeRotationType.WEIGHTED) {
              const groupedCreatives = groupCreatives(creatives)
              const isTotalCorrect = groupedCreatives.every((group) => {
                return group.some(creative => !creative.weight) || getCreativesTotalWeight(group) === 100
              })

              if (!isTotalCorrect) {
                errors.push(context.createError({
                  path: 'creatives.totalAllocatedValidation',
                  message: 'Total Allocated must be 100% for each group',
                }))
              }
            }

            if (endemicChannels.includes(channelId) && eNewsLetterEndemicFormats.includes(endemicFormatId as EndemicFormatType)) {
              const hasStaticCreative = creatives.some(creative => !creative.thirdPartyServed)

              if (!hasStaticCreative) {
                errors.push(context.createError({
                  path: 'creatives.creatives',
                  message: 'Please select at least one static creative to enable E-newsletter format',
                }))
              }
            }

            return errors.length === 0 || new ValidationError(errors)
          },
        )
      },
    })
  }

  return {
    creatives: whenChannelId(
      baseSchema,
      channelId => whenEndemicFormatId(
        baseSchema,
        endemicFormatId =>
          whenRotationTypeCallback(baseSchema, creativeRotationType =>
            buildSchema({
              channelId,
              endemicFormatId,
              creativeRotationType,
              areCreativesRequired,
            }))),
    ).required(),
  }
}
